<template>
  <div>
    
    <div v-if="carregando" style="text-align:center;">
      <pm-ProgressSpinner />
    </div>
    
    <div class="p-fluid formgrid grid" style="margin-top:-20px;" v-else="carregando">
      <PeriodStats :turma_id="turma_id" :disciplina_id="disciplina_id" :disciplina_fantasia_id="disciplinas_fantasia_id" :aulas_lancadas="presencasAlunosView[0].tableColuna.length" :tabelaDias="tabelaDias"/>

      <div class="field col-12 md:col-12 lg:col-12" >
        <div class="pm-card card-resp" >
          <div class="p-datatable-wrapper table-responsive" >
            <table role="table" class="p-datatable-table">
              <thead class="p-datatable-thead" role="rowgroup">
                <!-- <div v-if="presencasAlunosView" class="attendance-container">
                    <span class="attendance-label">Aulas lançadas: </span>
                    <span class="item_count">{{ presencasAlunosView[0].tableColuna.length }}</span>
                </div>  -->
                <tr role="row" >

                  <th class="thead_column col-4" tabindex="0" role="cell" aria-sort="none" style="font-size: 14px; vertical-align: middle !important;">
                    
                    <div class="p-column-header-content">
                      <span class="p-column-title">Nome</span>
                    </div>
                  </th>

                  <th class="thead_column" v-for="(item , index) of presencasAlunosView[0].tableColuna" :key="index"   style="font-size: 14px; vertical-align: middle !important;text-align:center;">
                    <div class="p-column-header-content" style="display: block !important;">
                      <span class="p-column-title">{{item}}</span>
                    </div>
                  </th>

                  <th class="thead_column" style="font-size: 14px; vertical-align: middle !important;text-align:center;">
                    <div>
                      <span class="p-column-title">Total</span>
                    </div>
                  </th>
                </tr>

                <tr class="thead_column" style="text-align: center;">
                  <td> - - </td>
                  <td v-for="it in datas_ativas" :key="it">
                    <span class="p-column-title">
                      <button class="p-button p-component p-button-icon-only p-button-sm btn-color table_style"
                        @click="abrirConteudo(it)"
                        type="button" title="Visualizar conteudo aplicado."
                        style="margin-left: 5px; width: 0px !important; height: 26px !important; background: #1d8d07;border: 1px solid #1d8d07;">
                        <span class="fa fa-list-alt p-button-icon"></span>
                        <span class="p-button-label">&nbsp;</span>
                      </button>
                    </span>
                  </td>
                  <td>
                    <span class="p-column-title">
                      <button class="p-button p-component p-button-icon-only p-button-sm btn-color table_style"
                        @click="abrirTodosOsConteudo()"
                        type="button" title="Visualizar conteudo aplicado."
                        style="margin-left: 5px; width: 0px !important; height: 26px !important; background: #096bb8;border: 1px solid #096bb8;">
                        <span class="fa fa-list-alt p-button-icon"></span>
                        <span class="p-button-label">&nbsp;</span>
                      </button>
                    </span>
                  </td>
                </tr>
              </thead>

              <tbody class="p-datatable-tbody" role="rowgroup">


                <tr class="body_column" v-for="(item , index) of presencasAlunosView" :key="index" >
                  <td style="text-align:left;" >
                    <span class="size-presenca" style="white-space: pre;" >
                      {{item.nome}}
                      <!-- Exibicao transferencia nova-->

                      <span v-if="item.historico_saida_entrada_turma != undefined && item.historico_saida_entrada_turma.length">
                        <i class="fa fa-info-circle"
                          style="font-size: 20px; color: #ffd233;margin: 10px 0px 0px 10px;"
                          :title="item.historico_saida_entrada_turma[item.historico_saida_entrada_turma.length - 1].evento+': '+item.historico_saida_entrada_turma[item.historico_saida_entrada_turma.length - 1].data_evento_br">
                        </i>
                      </span>

                      <span v-else-if="item.entrou_no_prazo_certo == false">
                        <i class="fa fa-info-circle"
                          style="font-size: 20px; color: #ffd233;margin: 10px 0px 0px 10px;"
                          :title="'Matriculado: '+item.data_entrada_turma_br">
                        </i>
                      </span>

                      <span v-if="item.evento_saida[0].tipoEvento == -1" @click="buscarHistoricoDoAlunoDePresenca(item)">

                        <i class="fa fa-history"
                          style="font-size: 20px; color: #3369ff;margin: 10px 0px 0px 10px; cursor: pointer;"
                          :title="'Histórico'">
                        </i>
                      </span>

                      <span v-if="item.evento_saida[0].tipoEvento == -1 && item.historico_saida_entrada_turma != undefined && item.historico_saida_entrada_turma.length > 1"
                        @click="buscarDadosDeEntradaESaidaAluno(item.historico_saida_entrada_turma)">
                        <i class="fa fa-exchange"
                          style="font-size: 20px; color: #3369ff;margin: 10px 0px 0px 10px;"
                          :title="'Entrada e Saída de Turma'">
                        </i>
                      </span>

                      <!--<span class="badge bg-warning table_style ml-2"  v-if="item.historico_saida_entrada_turma != undefined && item.historico_saida_entrada_turma.length">
                        <u>
                          {{item.historico_saida_entrada_turma[item.historico_saida_entrada_turma.length - 1].evento}}: {{item.historico_saida_entrada_turma[item.historico_saida_entrada_turma.length - 1].data_evento_br}}
                        </u>
                      </span>-->
                      <!-- Exibicao antinga-->
                      <!--<span class="badge bg-warning table_style ml-2"  v-else-if="item.entrou_no_prazo_certo == false">
                        <u>
                          Matriculado: {{item.data_entrada_turma_br}}
                        </u>
                      </span>-->

                      <!-- Exibicao transferencia antinga-->
                      <span class=""  v-if="item.evento_saida[0].tipoEvento !== -1">
                        <i
                          class="fa fa-info-circle"
                          style="font-size: 20px; color: #ffd233;margin: 10px 0px 0px 10px;"
                          :title="
                            item.evento_saida[item.evento_saida.length - 1].tipoEvento == 'Entrou na turma errada' ||
                              item.evento_saida[item.evento_saida.length - 1].tipoEvento == 'Transferido' ||
                              item.evento_saida[item.evento_saida.length - 1].tipoEvento == 'Transferencia de Turma' ? 'Transferido: ' + item.evento_saida[item.evento_saida.length - 1].data_formato_br
                            : 'Evadido: ' + item.evento_saida[item.evento_saida.length - 1].data_formato_br"
                        >
                        </i>
                        <!-- <u>
                          {{item.evento_saida[item.evento_saida.length - 1].tipoEvento == 'Entrou na turma errada' ||
                            item.evento_saida[item.evento_saida.length - 1].tipoEvento == 'Transferido' ||
                            item.evento_saida[item.evento_saida.length - 1].tipoEvento == 'Transferencia de Turma' ? 'Transferido' : 'Evadido'}}:
                          {{item.evento_saida[item.evento_saida.length - 1].data_formato_br}}
                        </u> -->

                      </span>

                      <!--<pm-Button v-if="item.evento_saida[0].tipoEvento == -1 && item.historico_saida_entrada_turma != undefined && item.historico_saida_entrada_turma.length > 1" type="button" icon="pi pi-sync" style="padding-top: 24px;"
                        title="Entrada e Saída de Turma" class="p-button-rounded p-button-info p-button-text"  @click="buscarDadosDeEntradaESaidaAluno(item.historico_saida_entrada_turma)">
                      </pm-Button>-->
                    </span>
                  </td>
                  <td v-for="n in item.presencas1" :key="n" role="cell" style="font-size:12px; vertical-align: middle !important;text-align:center;">
                    <div v-if="n.presenca == undefined">

                      <pm-button  title="O aluno não estava na Turma. Clique aqui caso queira corrigir essa informação!" style="cursor: pointer;"
                        @click="dialogActionEditar(item.nome,n, 1,item.id)" >
                        <p style="font-size:14px;"> - </p>
                      </pm-button>
                    </div>
                    <div v-if="n.presenca == 1" style="color:#13b527">
                      <pm-button  title="Editar" style="cursor: pointer;" @click="dialogActionEditar(item.nome,n, 1)">
                        <p style="font-size:14px;" v-if="paraImprimir">P</p>
                        <span v-else  class="badge bg-success infoTabela" >P</span>
                      </pm-button>
                    </div>
                    <div v-if="n.presenca == 0 && n.justificada_falta == 0" style="color: #df1212;">
                      <pm-button  title="Justificar falta" style="cursor: pointer;" @click="dialogActionEditar(item.nome,n, 0)">
                        <p style="font-size:14px;" v-if="paraImprimir">F</p>
                        <span v-else  class="badge bg-danger infoTabela" >F</span>
                      </pm-button>
                    </div>
                    <div v-if="n.presenca == 0 && n.justificada_falta == 1" style="color:#e97d20;">
                      <pm-button  title="Editar" style="cursor: pointer;" @click="dialogActionFaltaJustificada(item.nome, n)">
                        <p style="font-size:14px;" v-if="paraImprimir">FJ</p>
                        <span v-else  class="badge bg-warning infoTabela">FJ</span>
                      </pm-button>
                    </div>
                  </td>
                  <td style="color:#155ad9; text-align:center;" v-if="paraImprimir">
                    <div>
                      {{item.cntPresencas}}
                    </div>
                  </td>
                  <td style="color:#155ad9; font-size:12px; vertical-align: middle !important; text-align:center;" v-if="!paraImprimir">
                    <div>
                      <pm-button  title="Total de presenças" style="cursor: pointer;">
                        <span  class="badge bg-primary" style="font-size:12px;">{{item.cntPresencas}}</span>
                      </pm-button>
                    </div>
                  </td>
                </tr>
              </tbody>

              <tbody>
                <tr class="thead_column" style="text-align: center;">
                  <td> - - </td>
                  <td v-for="it in datas_ativas" :key="it">
                    <span class="p-column-title">
                      <pm-Button
                        :disabled="situacaoAno == 0"
                        title="Apagar presenças do dia."
                        type="button"
                        @click="/*deletarPresenca(it)*/perguntaDeletarPresenca(it)"
                        label=""
                        icon="pi pi-trash"
                        class="p-button-danger p-button-sm btn-color table_style"
                        style="margin-left: 5px; width: 0px !important;height: 26px !important;"
                      >
                      </pm-Button>
                    </span>
                  </td>
                  <td> --  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <pm-Dialog :header="nomeTipo" v-model:visible="displayMaximizableEdit" :style="{width: '100vw'}" :maximizable="true" :modal="true">
          <div class="field col-12" >
            <div>
              <button   :disabled="situacaoAno == 0"  class="btn btn-info" v-if="podeEditar == 0" @click="this.desativado = !this.desativado" style="margin-bottom:15px;">liberar Edição Para Falta</button>
              <button   :disabled="situacaoAno == 0"  class="btn btn-primary" v-if="faltaJustificadaDialog" @click="openArquivos">Visualizar arquivos já adicionados</button>
              <pm-DataTable v-if="!faltaJustificadaDialog" :value="item_edicao" @destroy="this.destroy()"  dataKey="id" :rowHover="true" filterDisplay="menu" v-model:filters="filters"
                responsiveLayout="scroll">

                <pm-Column field="data"  header="Nome" class="size-tb-modal-presenca" >
                  <template #body="{data}">
                    <div>
                      {{data.nome}}
                    </div>
                  </template>
                </pm-Column>
                <pm-Column field="data" header="PRESENTE" style="font-size: 14px;vertical-align:middle !important">
                  <template #body="{data}" >
                    <div>
                      <div class="form-check" v-if="acesso.editar">
                        <label
                          class="form-check-label"
                          style="color: #066e4f; font-weight: 900"
                        >
                          <!--<input
                                v-if="podeEditar != 1"
                                :disabled="podeEditar != 1"
                                type="radio"
                                class="form-check-input"
                                :name="'optradio1'"
                                :checked="data.presenca == 1"
                              />-->
                          <input
                            v-if="podeEditar != 1"
                            type="radio"
                            :disabled="desativado"
                            class="form-check-input"
                            :name="'optradio1'"
                            :checked="data.presenca == 1"
                            @click="editarPresencaDiaMarcado(1, data)"
                          />
                          <input
                            v-if="podeEditar == 1"
                            :disabled="!podeEditar"
                            type="radio"
                            class="form-check-input"
                            :name="'optradio1'"
                            :checked="data.presenca == 1"
                            @click="editarPresencaDiaMarcado(1, data)"
                          />
                        </label>
                      </div>
                      <div v-else>
                        <div class="alert alert-info">
                          Usuário Não Autorizado
                        </div>
                      </div>
                    </div>
                  </template>
                </pm-Column>
                <pm-Column field="data" header="AUSENTE" style="font-size: 14px;vertical-align:middle !important">
                  <template #body="{data}">
                    <div>
                      <div class="form-check" v-if="acesso.editar">
                        <label
                          class="form-check-label"
                          style="color: #f90d0d; font-weight: 900"
                        >
                          <!--<input
                                v-if="podeEditar != 1"
                                :disabled="podeEditar != 1"
                                type="radio"
                                class="form-check-input"
                                :name="'optradio1'"
                                :checked="data.presenca == 0"
                              />-->
                          <input
                            v-if="podeEditar != 1"
                            type="radio"
                            :disabled="desativado"
                            class="form-check-input"
                            :name="'optradio1'"
                            :checked="data.presenca == 0"
                            @click="editarPresencaDiaMarcado(0, data)"
                          />
                          <input
                            v-if="podeEditar"
                            type="radio"
                            class="form-check-input"
                            :name="'optradio1'"
                            :checked="data.presenca == 0"
                            @click="editarPresencaDiaMarcado(0, data)"
                          />
                        </label>
                      </div>
                      <div v-else>
                        <div class="alert alert-info">
                          Usuário Não Autorizado
                        </div>
                      </div>

                    </div>
                  </template>
                </pm-Column>
              </pm-DataTable>
              <!-- tabela arquivos -->
              <br>
              <button  :disabled="situacaoAno == 0 || isButtonDisabled"  class="btn btn-primary" @click="editarPresencaDia()" v-if="(acesso.editar && podeEditar == 1) || !this.desativado " >Editar Presença</button>
                 &nbsp
              <button  :disabled="situacaoAno == 0" class="btn btn-primary" @click="limparCampos" v-if="acesso.editar && podeEditar == 0">Justificar ausência</button>

              <button  :disabled="situacaoAno == 0"  class="btn btn-danger" @click="apagarPresenca()" v-if="acesso.editar" style="float: right;">Apagar Presença</button>
            </div>
          </div>

          <div class="p-fluid formgrid grid" v-if="podeEditar == 2">
            <h6>Justificar falta</h6>
            <div class="field col-12 md:col-12">
              <label for="ativiade">Informe a justificativa(motivo do aluno não ter participado da aula):</label>
              <pm-Textarea   :disabled="displayMaximizableEdit1"  rows="6" @change="validateForm" v-model="justificativaDoAluno.justificacao_conteudo" :class="{'p-invalid': validationErrors.justificacao_conteudo  && submitted}" />
              <small v-show="validationErrors.justificacao_conteudo && submitted" class="p-error">A justificativa é obrigatória!</small>
            </div>
            <div class="field col-12 md:col-12">
              <label for="arquivo">Arquivo que comprove a justificativa(não é obrigatório):</label>
              <va-file-upload

                upload-button-text="Adicionar arquivo"
                :disabled="displayMaximizableEdit1"
                :undo="false"
                v-model="justificativaDoAluno.justificativa_arquivo"
                file-types=".png,.PNG,.jpg,.JPG,.jpeg,.JPEG,.pdf,.PDF,.doc,.DOC,.docx,.DOCX"
              />
            </div>
            <button  class="btn btn-primary" :disabled="botaoDisabled" v-if="!faltaJustificadaDialog" @click="acaoJustificarAusencia">Enviar justificativa</button>
            <button  class="btn btn-primary" :disabled="botaoDisabled || displayMaximizableEdit1" v-if="faltaJustificadaDialog"  @click="acaoJustificarAusencia">Editar justificativa</button>
          </div>
        </pm-Dialog>

        <!--tabela de arquivos -->
        <pm-Dialog  header="Arquivos do aluno" v-model:visible="displayMaximizableEdit1"  :maximizable="true">
          <pm-DataTable v-if="faltaJustificadaDialog" :value="listaDeArquivoAluno" @destroy="this.destroy()"   dataKey="id" :rowHover="true" filterDisplay="menu"
            responsiveLayout="scroll">
            <pm-Column field="data"  header="Nome" class="size-tb-modal-presenca">
              <template #body="{data}">
                <div>
                  {{data.nome}}
                </div>
              </template>
            </pm-Column>
            <pm-Column field="data" header="Ações" style="font-size: 14px;vertical-align:middle !important">
              <template #body="{data}" >
                <pm-Button title="Visualizar arquivo" type="button"  @click="visualizarArquivoModal(data.arquivo)" label="Visualizar documento"
                  icon="pi pi-file" class="p-button-primary p-button-sm btn-color;table_style" style="margin-left:5px">
                </pm-Button>
                <pm-Button :disabled="displayMaximizableEdit2" title="Apagar arquivo" type="button"  @click="deletarArquivo(data)" label="Apagar"
                  icon="pi pi-times-circle" class="p-button-danger p-button-sm btn-color;table_style" style="margin-left:5px">
                </pm-Button>
              </template>
            </pm-Column>
          </pm-DataTable>
        </pm-Dialog>

        <pm-Dialog position="topright" header="Documento" v-model:visible="displayMaximizableEdit2" :style="{width: '100vw', height: '50vw'}" :maximizable="true">
          <div class="col-md-12">
            <iframe :src="arquivosView" width="100%" height="1000px"></iframe>
          </div>
        </pm-Dialog>
      </div>
      <pm-Dialog header="Histórico de entrada(s) e saída(s) da turma"  v-model:visible="showModalEntradaESaida" :style="{ width: '15vw' }" :breakpoints="{ '960px': '75vw', '641px': '100vw' }">
        <div v-for="item in historicoEntradaESaida"  :key="item.id">
          <p>{{ item.evento }}: {{ item.data_evento_br }}</p>
          <hr>
        </div>
      </pm-Dialog>
      <pm-Dialog :header="'Histórico de presenças do aluno(a) ' + historicoDePresencas.nome + ' até o mês atual:'"  v-model:visible="showModalHistorioPresencas" :style="{ width: '30vw' }" :breakpoints="{ '960px': '75vw', '641px': '100vw' }">

        <pm-DataTable
          :value="historicoDePresencas.info.turmasInfo"
          filterDisplay="menu"
          responsiveLayout="scroll"
          :rows="40"
        >

          <pm-Column
            field="escola"
            header="Escola"
            style="font-size: 14px; vertical-align: middle !important"
          >
            <template #body="{ data }">
              <p>
                {{data.escolaNome.nome}}
              </p>
            </template>
          </pm-Column>

          <pm-Column
            field="serie"
            header="Série"
            style="font-size: 14px; vertical-align: middle !important"
          >
            <template #body="{ data }">
              <p>
                {{data.serieNome.nome}}
              </p>
            </template>
          </pm-Column>

          <pm-Column
            field="turma"
            header="Turma"
            style="font-size: 14px; vertical-align: middle !important"
          >
            <template #body="{ data }">
              <p>
                {{data.turmaNome}}
              </p>
            </template>
          </pm-Column>

          <pm-Column
            field="total_presenca"
            header="Total De Presenças do aluno"
            style="font-size: 14px; vertical-align: middle !important"
          >
            <template #body="{ data }">
              <p>{{data.total_presencas + data.total_presencas_manual}}</p>
            </template>
          </pm-Column>

          <pm-Column
            field="presencas_lancadas"
            header="Total De Aulas Lançadas"
            style="font-size: 14px; vertical-align: middle !important"
          >
            <template #body="{ data }">
              <p>{{data.total_lancadas + data.total_lancadas_manual}}</p>
            </template>
          </pm-Column>


          <pm-Column
            field="percentual"
            header="Porcentagem"
            style="font-size: 14px; vertical-align: middle !important"
          >
            <template #body="{ data }">
              <p v-if="data.total_lancadas !== 0 || data.total_lancadas_manual !== 0">
                {{ Math.round(((data.total_presencas + data.total_presencas_manual) / (data.total_lancadas + data.total_lancadas_manual)) * 100) }}%
              </p>
            </template>
          </pm-Column>
        </pm-DataTable>
        <br><br>
        <div style="float: right;">
          <p>
          <h6>Presenças Totais: {{historicoDePresencas.info.total_presencas_geral + historicoDePresencas.info.total_presencas_manual_geral}}</h6>
          <h6>Aulas Lançadas Totais: {{historicoDePresencas.info.total_lancadas_geral + historicoDePresencas.info.total_lancadas_manual_geral}}</h6>
          <h6 v-if="historicoDePresencas.info.total_lancadas_geral !== 0 || historicoDePresencas.info.total_lancadas_manual_geral !== 0">Porcentagem Total: {{ Math.round(((historicoDePresencas.info.total_presencas_geral + historicoDePresencas.info.total_presencas_manual_geral) / (historicoDePresencas.info.total_lancadas_geral + historicoDePresencas.info.total_lancadas_manual_geral)) * 100) }}%</h6>
          </p>
        </div>
      </pm-Dialog>


      <pm-Dialog header="Deseja Deletar essas Presenças?" v-model:visible="displayDeletar"
        :breakpoints="{ '500px': '75vw', '400px': '90vw' }"
        :style="{ width: '50vw' }" :maximizable="false" :modal="true">
        <div class="field col-12" >
          <pm-button class="p-button p-component"
            title="Sim" style="cursor: pointer; margin-right:10px;" @click="deletarPresenca(this.item_deletar)">
            Sim
          </pm-button>
          <pm-button class="p-button p-component p-button-danger"
            title="Não" style="cursor: pointer;" @click="this.displayDeletar=false;">
            Não
          </pm-button>
        </div>
      </pm-Dialog>

    </div>
  </div>
</template>

<script>
import PeriodStats from "@/components/presenca/PeriodStats.vue";
import { Presenca } from "@/class/presenca";
import { Aluno } from "@/class/alunos";
import axios from 'axios';

import Util from "@/utils/rota";

export default {
  name: "TableProfPresenca01",
  props: {
    tabelaDias: { type: Array, default: () => [] },
    presencasAlunosView: { type: Array, default: () => [] },
    mes:null,
    mes_sel:null,
    mes_nome:null,
    etapaDeEnvioDePresenca:null,
    acesso:[],
    disciplina_id: null,
    disciplinas_fantasia_id: 0,
    turma_id: null
  },
  components: {
      PeriodStats,
  },
  data () {
    return {
      carregando:true,
      datas_ativas:[],
      botaoDisabled:false,
      dataSelecionada:'',
      arquivosView:'',
      aluno_id:'',
      anoUsado:'',
      pathDoSite: axios.defaults.baseURL.replace('api/v1',''),
      displayMaximizableEdit:false,
      displayMaximizableEdit1:false,
      displayMaximizableEdit2:false,
      displayDeletar:false,
      item_deletar:{},
      item_edicao:[],
      item_nome_edicao:null,
      marcado_item: {},
      podeEditar:1,
      nomeTipo:'',
      justificativaDoAluno: {
        justificativa_arquivo:'',
        justificacao_conteudo:'',
      },
      submitted:false,
      validationErrors: {},
      paraImprimir:false,
      faltaJustificadaDialog:false,
      listaDeArquivoAluno:[],
      desativado:1,
      historicoEntradaESaida: [],
      showModalEntradaESaida: false,
      historicoDePresencas: [],
      showModalHistorioPresencas: false,
      currentMonth: '',
      monthNames : [
          "janeiro", "fevereiro", "marco", "abril",
          "maio", "junho", "julho", "agosto",
          "setembro", "outubro", "novembro", "dezembro"
      ],
      pageLoading: false,
      situacaoAno:0,
      isButtonDisabled: 0
    }
  },

  async beforeMount(){
    this.situacaoAno = parseInt(sessionStorage.getItem("anoSitucao"));
    const currentDate = new Date();
    this.currentMonth = this.monthNames[currentDate.getMonth()];
    await this.logado();
    await this.datasAtivas();
    this.carregando = false;
   // this.ordernarNomes();
  },
  methods: {
    ordernarNomes() {
      this.presencasAlunosView.sort((a, b) => {
        const nameA = a.nome.toUpperCase(); // Convert name to uppercase for case-insensitive sorting
        const nameB = b.nome.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }

        if (nameA > nameB) {
          return 1;
        }

        return 0; // Names are equal
      });
    },
    apagarPresenca() {
      const payload = {
        id: this.item_edicao.data.id_presenca,
        disciplina_id: this.item_edicao.data.disciplina_id,
        turma_id: this.item_edicao.data.turma_id,
        segmento_id: this.item_edicao.data.segmento_id,

      }
      if (window.confirm("Você realmente deseja apagar o dado selecionado?")) {
            Presenca.apagarPresencaDoAluno(payload).then((resp) => {
            this.$vaToast.init({
                  message: "Dado apagado com sucesso!",
                  iconClass: 'fa-star-o',
                  position: 'top-right',
                  duration: 3000,
                  color: 'success',
                  fullWidth: false,
            });
          }).catch((err) => {
              this.$vaToast.init({
                  message: "Problema ao apagar o dado!",
                  iconClass: 'fa-star-o',
                  position: 'top-right',
                  duration: 3000,
                  color: 'danger',
                  fullWidth: false,
              });
            }).finally(() => {
              this.carregando = false;

          });
      }
      this.item_edicao = '';
      this.test(this.mes_sel,this.mes_nome);
      this.displayMaximizableEdit = false;
      this.pageLoading = false;
    },
    buscarHistoricoDoAlunoDePresenca(items) {
      console.log(items);
      const payload = {
        turma_atual_id: items.turma_id,
        aluno_id: items.id,
        disciplina_id: this.disciplina_id,
        disc_fantasia_id: this.disciplinas_fantasia_id == '' ? 0 : this.disciplinas_fantasia_id

      }
      this.carregando = true;
      Presenca.buscarHistorioDePresencasDeAluno(payload).then((resp) => {
        this.historicoDePresencas = resp.data;
        this.historicoDePresencas.nome = items.nome;
        this.showModalHistorioPresencas = true;
      }).catch((err) => {
          this.$vaToast.init({
              message: "Problema ao buscar os dados do aluno!",
              iconClass: 'fa-star-o',
              position: 'top-right',
              duration: 3000,
              color: 'danger',
              fullWidth: false,
          });
        }).finally(() => {
          this.carregando = false;
      });
    },
    buscarDadosDeEntradaESaidaAluno(items) {
      this.historicoEntradaESaida = [];
      this.historicoEntradaESaida = items;
      this.showModalEntradaESaida = true;
    },
    datasAtivas(){
      this.datas_ativas = []
      if( this.presencasAlunosView.length>0){
          var df = this.presencasAlunosView[0].presencas1;
      }
      this.datas_ativas = df
    },
    async logado() {
      const token = sessionStorage.getItem("token");
      const util = new Util();
      const data = await util.WhoIam(token);
      this.funcao = data.funcao;
    },
    pegarANumeracao() {
      if (this.listaDeArquivoAluno.length) {
        this.listaDeArquivoAluno.sort((a,b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0));
        const arquivo =  this.listaDeArquivoAluno[this.listaDeArquivoAluno.length - 1];
        //Receber o numero do arquivo, ex: Justificativa_de_Falta_Documento_4
        const receberNumeracaoDoArquivo = arquivo.nome.slice(-1);
        return parseInt(receberNumeracaoDoArquivo) + 1;
      }
      else {
        return 1;
      }
    },
    visualizarArquivoModal(arquivo) {
      this.arquivosView = arquivo;
      this.displayMaximizableEdit2 = true;
      this.desativado = 1;
    },
    async buscarArquivos() {
      const dataEnvia = this.item_edicao.data.data;
      const data1 = await Aluno.buscarArquivoAluno(this.aluno_id, "justificarFalta", dataEnvia);
      this.listaDeArquivoAluno = [];
      for (const el of data1.data) {
        const novo = {
          id:el.id,
          nome: el.nome,
          path: el.path,
          aluno_id: el.aluno_id,
          arquivo: this.pathDoSite + 'storage/'+el.path,
        }
        this.listaDeArquivoAluno.push(novo);
      }
    },
    openArquivos() {
      this.buscarArquivos();
      if (this.listaDeArquivoAluno.length) {
        this.displayMaximizableEdit1 = !this.displayMaximizableEdit1;
      }
      else {
        alert("Não existem arquivos cadastrados!");
      }
    },
    async deletarArquivo(data) {
      const novo = {
        aluno_id: data.aluno_id,
        id: data.id
      }
      try {
        if (window.confirm("Você realmente deseja apagar o arquivo?")) {
          const data = await Aluno.deletarArquivo(novo);
          await this.buscarArquivos();
          //Fechar modal se nao tiver arquivo
          if (!this.listaDeArquivoAluno.length) {
            this.displayMaximizableEdit1 = false;
          }
        }
      }
      catch(e) {
        alert("Problema ao apagar o arquivo!");
      }
    },
    async dialogActionFaltaJustificada(nome, item, n) {
      this.limparCampos();
      this.item_nome_edicao = nome;
      const items = {
        data: item,
      };
      this.aluno_id = items.data.aluno_id;
      this.item_edicao = items;
      this.nomeTipo = "Falta justificada";
      this.podeEditar = 2;
      const data = await Presenca.buscarPresenca(item.presenca_id);
      this.justificativaDoAluno.justificacao_conteudo = data.data.justificacao_conteudo;
      //receber o ano da data
      let ano = data.data.data.split(" ");
      ano = ano[0];
      ano = ano.split('-');
      ano = ano[0];
      this.anoUsado = ano;
      this.aluno_id = item.aluno_id;
      //buscar os arquivos do aluno
      await this.buscarArquivos();
      this.faltaJustificadaDialog = true;
      this.displayMaximizableEdit = true;
    },
    limparCampos() {
      this.pageLoading = true;
      this.podeEditar = 2;
      this.justificativaDoAluno.justificativa_arquivo = '';
      this.justificativaDoAluno.justificacao_conteudo = '';
      delete this.validationErrors['justificacao_conteudo'];
      this.pageLoading = false;
    },
    validateForm() {
        if (this.justificativaDoAluno.justificacao_conteudo == null || this.justificativaDoAluno.justificacao_conteudo == '')
            {this.validationErrors['justificacao_conteudo'] = true;}
        else
            {delete this.validationErrors['justificacao_conteudo'];}
        return !Object.keys(this.validationErrors).length;
    },
    async acaoJustificarAusencia() {
      this.submitted = true;
      if (this.validateForm() && this.submitted) {
        const novo = {
          presenca_id:  this.item_edicao.data.presenca_id,
          justificada_falta: 1,
          justificacao_conteudo: this.justificativaDoAluno.justificacao_conteudo
        }
        //Justificar a falta
        try {
          const data = await Presenca.justificarAFalta(novo);
        }
        catch(e) {
        }
        //adicionar arquivo
        if (this.justificativaDoAluno.justificativa_arquivo != '') {
          //enviar arquivos
          try {
            let arquivoNumeracao = this.pegarANumeracao();
            const dataEnvia = this.item_edicao.data.data;
            this.botaoDisabled = true;
            for (let i = 0; i < this.justificativaDoAluno.justificativa_arquivo.length; i++) {
              const formData = new FormData();
              formData.append('documento_auxiliar', this.justificativaDoAluno.justificativa_arquivo[i]);
              formData.append('nome', "Justificativa_de_Falta_Documento_"+String(arquivoNumeracao++));
              formData.append('local', "justificarFalta");
              formData.append('data', dataEnvia);
              const data1 = await Aluno.cadastrarArquivo(this.item_edicao.data.aluno_id, formData);
            }
            this.botaoDisabled = false;
          }
          catch(e) {
            this.$vaToast.init({
              message: "Problema ao adicionar o arquivo!",
              iconClass: 'fa-star-o',
              position: 'top-right',
              color:'danger',
              duration: 2500,
              fullWidth: false,
            })
            this.botaoDisabled = false;
          }
        }
        this.$vaToast.init({
          message: "Justitifcativa enviada com sucesso!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })
        this.test(this.mes_sel,this.mes_nome);
        this.displayMaximizableEdit = false;
      }
    },
    dialogActionEditar(nome,item, podeEditar,id = 0){
      this.faltaJustificadaDialog = false;
      this.displayMaximizableEdit = true;
      this.item_nome_edicao = nome;

      this.item_edicao = item;
      this.item_edicao["nome"] = this.item_nome_edicao;

      let items;
      items = { data: this.item_edicao};

      this.item_edicao = [];
      this.item_edicao = items;
      this.item_edicao_id = id;
      this.podeEditar = podeEditar;
      if (podeEditar) {
        this.nomeTipo = "Editar presença";
      }
      else {
        this.nomeTipo = "Justificar ausência";
      }
    },
    editarPresencaDiaMarcado(check,item){
      this.marcado_item = item;
      this.marcado_item["check"] = check;
    },
    async editarPresencaDia(){
        this.isButtonDisabled = true;
        this.carregando = true;

        if(this.marcado_item.check!=undefined && this.marcado_item.check!=null){
            const playload = {
              presenca: this.marcado_item.check
            }
          if(this.item_edicao_id){
            this.marcado_item.aluno_id = this.item_edicao_id
            this.marcado_item.presenca = playload.presenca
             const data = await Presenca.criarPresencaDoAluno(this.marcado_item)
          }else{

            const data = await Presenca.editarPresencaOne(playload, this.marcado_item.presenca_id);
          }
        }
      this.item_edicao_id = 0;
      this.test(this.mes_sel,this.mes_nome);
      this.displayMaximizableEdit = false;
      this.carregando = false;
      this.isButtonDisabled = true;

    },
    async test(mes, mesExibir){
      this.$emit('test', mes, mesExibir);
    },
    abrirTodosOsConteudo(){
      const it = this.datas_ativas[0]
      this.$emit('abrirTodosOsConteudo', this.mes_sel,it);
    },
    abrirConteudo(it){
      this.$emit('abrirConteudo',this.mes_sel, it);
    },
    perguntaDeletarPresenca(dados){
      this.displayDeletar = true;
      this.item_deletar = dados;
    },
    async deletarPresenca (dados){
      this.carregando = true;
      if(this.funcao == 1 || this.funcao == 2){
        const res = await axios.delete('presencas/apagar-dia/turma/'+dados.turma_id,{
          params: {
            data: dados.data,
            disciplina: dados.disciplina_id,
            segmento_id: dados.segmento_id,
            escola_id: dados.escola_id,
            disc_fantasia_id: dados.disc_fantasia_id,
          }
        });

        this.datasAtivas();
        this.test(this.mes_sel,this.mes_nome);
      }else{
        const dat = dados.data.split(' ')
        const ano = dat[0].split('-');

        const res = await axios.delete('presencas/apagar-dia/turma/'+dados.turma_id+'/professor',{
          params: {
            data: dados.data,
            disciplina: dados.disciplina_id,
            segmento: dados.segmento_id,
            ano:ano[0],
            disc_fantasia_id: dados.disc_fantasia_id
          }
        });

        this.datasAtivas();
        this.test(this.mes_sel,this.mes_nome);
      }
      this.carregando = true;
    }
  }
};
</script>

<style>
.thead_column:hover {
    background: #e9ecef;
    color: #495057;
}
.thead_column {
    text-align: left;
    padding: 1rem 0.7rem;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    font-weight: 600;
    color: #495057;
    background: #f8f9fa;
    transition: box-shadow 0.2s;
    max-width: 0% !important;
}

.body_column td{
  text-align: left;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 0.6rem 0.5rem;
}
</style>

<style scoped>
  .attendance-container {
      float: right;
      padding-top: 17px;
      border-radius: 8px;
      background-color: #52a7ea;
      padding: 10px;
      margin-right: 10px;
      margin-top: 6px;
  }
  .attendance-label {
      font-weight: bold;
      border: 2px;
      padding: 14px;
      border-radius: 8px;
      color: aliceblue;
  }
  .item_count {
      vertical-align: middle !important; 
      padding: 6px;
      border-radius: 50%;
      border: 1px solid #ced4da;
      width: 32px;
      font-size: 18px;
      color: white;
      font-weight: 700;
  }


/* .thead_column:hover {
    background: #e9ecef;
    color: #495057;
}
.thead_column {
    text-align: left;
    padding: 1rem 0.7rem;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    font-weight: 600;
    color: #495057;
    background: #f8f9fa;
    transition: box-shadow 0.2s;
    max-width: 0% !important;
}

.body_column td{
  text-align: left;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 0.6rem 0.5rem;
} */

.infoTabela {
  font-size:12px!important;
  font-weight:normal!important;
}

.abrirModalFullScreen {
  width: 100% !important;
	height: 100% !important;
	position: absolute !important;
	left: 0 !important;
	top: 0 !important;
}

.card-resp{
  padding: 8px;
}

</style>

<style>
  .va-file-upload .va-file-upload__field .va-file-upload__field__button {
    border-radius: 4px!important;
  }
</style>


