<template>
  <div>
    <div class="p-fluid formgrid grid" v-if="completo == 0">
      <div class="col-12 md:col-12" v-if="!loading">
        <div class="col-12 md:col-12" v-for="(disciplina, index) in disciplinas" :key="disciplina" >
          <b>{{ disciplina.nome }}</b>
          <div class="col-12 md:col-12" v-if="edicao != 1">
            <div class="col-12 md:col-12" v-if="disciplina.conteudo" style="margin-top: 10px;" >
              <pm-Textarea  rows="3"  v-model="texto[index]['texto']" @keyup="saveAppliedContent();"  />
            </div>  
            <div class="col-12 md:col-12" v-else  style="margin-top: 10px;">
              <pm-Textarea  rows="3" v-model="texto[index]['texto']" @keyup="saveAppliedContent();" />
            </div>
          </div>
          <div class="col-12 md:col-12" v-else>
            <div class="col-12 md:col-12" v-if="disciplina.conteudo" style="margin-top: 10px;" >
              <pm-Textarea  rows="3"  v-model="texto[index]['texto']"/>
            </div>  
            <div class="col-12 md:col-12" v-else  style="margin-top: 10px;">
              <pm-Textarea  rows="3" v-model="texto[index]['texto']" />
            </div>
          </div>
        </div>

        <div class="row" style="float: right !important;flex-direction: row-reverse;width: 100%;">
          <div class="col-1 md:col-1" v-if="edicao == 1">
            <pm-Button :disabled="!situacaoAno" label="Salvar"  icon="pi pi-check" @click="editAppliedContent()" autofocus />
          </div>
        </div>
      </div>
      <div  v-if="loading" style="display: flex;width:100%">
        <pm-ProgressSpinner style="align-items: center;width:50px;height:50px;margin-top:50px;margin-bottom: 50px;" />
      </div>
    </div>

    <div class="p-fluid formgrid grid" v-if="completo == 1">
      <div class="field col-12 md:col-12" v-if="!loading">
        <div class=" field col-12 md:col-12">
          <button class="btn btn-sm btn-danger" @click="gerarPdf()" style="margin-top:10px;margin-right:5px;"
            title="Gerar Impressão">
            <i class="fa fa-file-pdf-o"></i> Pdf
          </button>
        </div>  
        <div class=" field col-12 md:col-12" id="dadosrel">
          <table style="width:100%;margin-top:20px;">
            <thead>
              <th style="color: #212529;text-align:center;padding: 10px;border: 1px solid #c1c1c1;width: 8%;">
                Dia
              </th>
              <th style="color: #212529;padding: 10px;border: 1px solid #c1c1c1;">
                Conteudo
              </th>
            </thead>
            <tbody v-for="conteudo in conteudo_mesal.conteudo" :key="conteudo">
              <tr v-for="c in conteudo.dados" :key="c" style="background-color: #e9e9e9;border: 1px solid #c1c1c1;padding: 10px !important;text-align: center;">
                <td style="border: 1px solid rgb(193 193 193);" >{{ c.dia }}</td>
                <td style="text-align:left;padding: 10px;border: 1px solid rgb(193 193 193);">
                  <span class="badge" style="background-color: #9f9f9f;">{{ c.disciplina_nome }}</span> {{ c.texto }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div  v-if="loading" style="display: flex;width:100%">
        <pm-ProgressSpinner style="align-items: center;width:50px;height:50px;margin-top:50px;margin-bottom: 50px;" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { PlanejamentoPedagogico } from "@/class/planejamentopedagogico";
import { LoginInfo } from "@/class/login";
import axios from "axios";
import { DisciplinaEscolar } from "@/class/disciplina.js";

export default defineComponent({
  props: {
    edicao:{required: true},
    dados:{required: true},
    mes_c:{required: true},
    completo:{required: true}
  },

  data() {
    return {
      loading:true,
      texto:[],
      disciplinas:[],
      disciplina:[],
      servidor_id:0,
      diaConteudo:[],
      disciplinaConteudo:[],
      conteudo_mesal:[],
      n_disc:"",
      meses: [
        { numero: "01", palavra: "janeiro" },
        { numero: "02", palavra: "fevereiro" },
        { numero: "03", palavra: "marco" },
        { numero: "04", palavra: "abril" },
        { numero: "05", palavra: "maio" },
        { numero: "06", palavra: "junho" },
        { numero: "07", palavra: "julho" },
        { numero: "08", palavra: "agosto" },
        { numero: "09", palavra: "setembro" },
        { numero: "10", palavra: "outubro" },
        { numero: "11", palavra: "novembro" },
        { numero: "12", palavra: "dezembro" },
      ],
      situacaoAno: 0
    };
  },

  async beforeMount() {
    this.situacaoAno = parseInt(sessionStorage.getItem("anoSitucao"));
    this.enviaConteudoObg(0)
    const token = sessionStorage.getItem("token");
    const whoiam = await LoginInfo.WhoIam(token);
    this.servidor_id = whoiam.data.usuario.id

    //console.log(this.dados)

    if(this.edicao == 1){
      if(this.completo == 0){
        if(this.dados.disc_fantasia_id != null){
          const data = await axios.get("/buscar-por-disciplinafantasia/" + this.dados.disc_fantasia_id);

          this.diaConteudo = [];
          this.diaConteudo = data.data;

          if(this.diaConteudo){
            const dt = await axios.get("/buscarprofessor/turma/disciplinas/"+this.diaConteudo.turma_id+"/"+this.diaConteudo.professor_id);
            this.disciplinaConteudo = dt.data;

            for (let i = 0; i < this.disciplinaConteudo.length; i++) {
              const element = this.disciplinaConteudo[i]["disciplina"];
              this.disciplinas.push(element)
            }

            let i = 0;
            for (const it of this.disciplinas) {
              const dt = await axios.get(`/conteudoAplicadoPorDisci/${it.id}/${this.dados.dia}/${this.mes_c}/${this.diaConteudo.ano}/${this.diaConteudo.turma_id}/${this.diaConteudo.professor_id}`);
              if(dt.data){
                it.conteudo = dt.data
                this.changeTexto(i,it.id,it.conteudo.texto)
              }else{
                this.changeTexto(i,it.id,null)
              }
              i++
            }
          }

        }else{
          if(this.dados.disciplina_id != 1){
            const dt = await DisciplinaEscolar.obtemUm(this.dados.disciplina_id);
            this.disciplinas.push(dt.data)
          }else{
            const dt = await axios.get('/disciplinas-turmas/turma/'+ this.dados.turma_id)
            this.disciplinas = dt.data.disciplinas_turma;
          }

          const arrData = this.dados.data.split("-");
          /*const dt = await DisciplinaEscolar.obtemUm(this.dados.disciplina_id);
          this.disciplinas.push(dt.data)*/
          let i = 0;
          for (const it of this.disciplinas) {
            const dt = await axios.get(`/conteudoAplicadoPorDisci/${it.id}/${parseInt(arrData[2])}/${this.mes_c}/${parseInt(arrData[0])}/${this.dados.turma_id}/0`);
            if(dt.data){
              it.conteudo = dt.data
              this.changeTexto(i,it.id,it.conteudo.texto)
            }else{
              this.changeTexto(i,it.id,null)
            }
            i++
          }
        }

      }else{
        if(typeof this.dados !== "undefined"){
          const ano = sessionStorage.getItem("anoSelecionado");
          if(this.dados.disc_fantasia_id){
            const data = await axios.get(`/conteudoAplicadoPorDisciFantasia/${this.dados.disc_fantasia_id}/mes/${this.mes_c}/ano/${ano}`);
            this.conteudo_mesal = data.data;
          }else{
            const data = await axios.get(`/conteudoAplicadoPorDisci/${this.dados.disciplina_id}/0/${this.mes_c}/${parseInt(ano)}/${this.dados.turma_id}/0`);
            this.conteudo_mesal = data.data;
          }
        }
      }
    }else{
      if(this.dados.disc_fantasia_id){
        const data = await axios.get("/buscar-por-disciplinafantasia/" + this.dados.disc_fantasia_id);

        this.diaConteudo = [];
        this.diaConteudo = data.data;

        if(this.diaConteudo){
          const dt = await axios.get("/buscarprofessor/turma/disciplinas/"+this.diaConteudo.turma_id+"/"+this.diaConteudo.professor_id);
          this.disciplinaConteudo = dt.data;

          for (let i = 0; i < this.disciplinaConteudo.length; i++) {
            const element = this.disciplinaConteudo[i]["disciplina"];
            this.disciplinas.push(element)
          }

          const arrData = this.dados.data.split("/");
          let i = 0;
          for (const it of this.disciplinas) {
            let mes = "";
            for (const m of this.meses) {
              if(m.numero == arrData[1]){
                mes = m.palavra
              }
            }

            const dt = await axios.get(`/conteudoAplicadoPorDisci/${it.id}/${arrData[0]}/${mes}/${this.diaConteudo.ano}/${this.diaConteudo.turma_id}/${this.diaConteudo.professor_id}`);
            if(dt.data){
              it.conteudo = dt.data
              this.changeTexto(i,it.id,it.conteudo.texto)
            }else{
              this.changeTexto(i,it.id,null)
            }
            i++
          }
        }
      }else{
        if(this.dados.disciplina_id != 1){
          const dt = await DisciplinaEscolar.obtemUm(this.dados.disciplina_id);
          this.disciplinas.push(dt.data)
        }else{
          const dt = await axios.get('/disciplinas-turmas/turma/'+ this.dados.turma_id)
          this.disciplinas = dt.data.disciplinas_turma;
        }

        const arrData = this.dados.data.split("/");
        let mes = "";
        for (const m of this.meses) {
          if(m.numero == arrData[1]){
            mes = m.palavra
          }
        }


        let i = 0;
        for (const it of this.disciplinas) {
         // console.log(it)
          const dt = await axios.get(`/conteudoAplicadoPorDisci/${it.id}/${parseInt(arrData[0])}/${mes}/${parseInt(arrData[2])}/${this.dados.turma_id}/0`);
          if(dt.data){
            it.conteudo = dt.data
            this.changeTexto(i,it.id,it.conteudo.texto)
          }else{
            this.changeTexto(i,it.id,null)
          }
          i++
        }
      }
    }

    this.loading = false
  },

  methods:{
    enviaConteudoObg(valor){
      sessionStorage.setItem("conteudoObrigatorio", valor);
      this.$emit('conteudoObg', valor);
    },

    changeTexto(pos,disciplina,msg){
      this.texto[pos] = { 'texto': msg, 'disciplina_id': disciplina}
    },

    changeStatus(st = 1){
      if(st){
        this.$emit('changeStatusModal', 0);
      }else{
        this.$emit('changeStatusModal', 1);
      }
    },

    async buscarConteudoDisciplina(fantasia) {
      if(fantasia === 1){
        const data = await axios.get("/buscar-por-disciplinafantasia/" + this.dados.disc_fantasia_id);
        this.diaConteudo = [];
        this.diaConteudo = data.data;
        if(this.diaConteudo){
          const dt = await axios.get("/buscarprofessor/turma/disciplinas/"+this.diaConteudo.turma_id+"/"+this.diaConteudo.professor_id);
          this.disciplinaConteudo = dt.data;

          for (let i = 0; i < this.disciplinaConteudo.length; i++) {
            const element = this.disciplinaConteudo[i]["disciplina"];
            this.disciplinas.push(element)
          }
        }
      }
    },

    async buscarProfessorTurma(turma, disciplina) {
      const data = await axios.get("/disciplinas-turmas/turma/"+turma+"/disc/"+disciplina);
      return data.data
    },

    async saveAppliedContent(){
      //this.enviaConteudoObg(0)
      const arrData = this.dados.data.split("/");
      const conteudo = []

      let count = 0;
      for (let i = 0; i < this.texto.length; i++) {
        if(this.texto[i].texto != null){
          const res = await this.buscarProfessorTurma(this.dados.turma_id, this.texto[i].disciplina_id)
          const playload = {
            turma_id: this.dados.turma_id,
            professor_id:  res.professor.professor_id,
            //professor_id:  this.diaConteudo.professor_id,
            disciplina_id: this.texto[i].disciplina_id,
            texto: this.texto[i].texto,
            dia:  parseInt(arrData[0]),
            mes:  parseInt(arrData[1]),
            edicao: 1
          }
          conteudo.push(playload)
          if(this.texto[i].texto != ""){
            this.enviaConteudoObg(1)
            count = count + 1
          }else{
            count = count - 1
          }
        }
      }    
      //this.toastSuccess("Conteúdo Salvo com Sucesso!")
      this.$emit('saveAppliedContent', conteudo, 0);
    },

    async editAppliedContent(){
      const arrData = this.dados.data.split("-");

      for (let i = 0; i < this.texto.length; i++) {
        if(this.texto[i].texto != null){
          //if(this.texto[i].texto.length > 3){
            const res = await this.buscarProfessorTurma(this.dados.turma_id, this.texto[i].disciplina_id)
            const playload = {
              turma_id: this.dados.turma_id,
              professor_id:  res.professor.professor_id,
              //professor_id:  this.diaConteudo.professor_id,
              disciplina_id: this.texto[i].disciplina_id,
              texto: this.texto[i].texto,
              dia:  parseInt(arrData[2]),
              mes:  parseInt(arrData[1]),
              edicao: 1
            }

            try {
              await PlanejamentoPedagogico.addPlanejamentoProf(playload);
              this.toastSuccess("Conteúdo Salvo com Sucesso!")
            } catch (error) {
              const str = `Ocorreu um erro ao Lançar o Conteudo Aplicado, Tente enviar Novamente! ${error}`
              this.toastErr(str)
              break;
            }
          /*}else{
            this.toastErr("Preencha corretamente o campo do conteudo aplicado, ele pode está vazio ou com menos de 3 caracteres!")
          }*/
        }
      }
    },

    gerarPdf(){
      if(typeof this.conteudo_mesal.cabecario === "undefined"){
        return false;
      }

      const mywindow = window.open('', 'PRINT', 'height=auto,width=800');

      mywindow.document.write(`<html xmlns:o="urn:schemas-microsoft-com:office:office"
        xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40">

        <head>
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
          <meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8">
          <meta charset="utf-8">
        <title>relatorio</title>`);
      mywindow.document.write(`
        <style>
          body {
              margin: 0;
              color: #212529;
              text-align: center;
              background-color: #fff;
          }
          table {
              border-collapse: collapse;
          }
          table tr td, table tr th{
            font-size: 12px !important;
          }
          thead {
            display: table-header-group;
            vertical-align: middle;
            border-color: inherit;
          }
          tr {
            display: table-row;
            vertical-align: inherit;
            border-color: inherit;
          }
          .table-bordered {
              border: 1px solid #dee2e6;
          }
          .table {
              width: 100%;
              margin-bottom: 1rem;
              color: #212529;
          }
          .table-bordered thead td, .table-bordered thead th {
              border-bottom-width: 2px;
          }
          .table thead th {
            vertical-align: bottom;
            border-bottom: 2px solid #dee2e6;
          }
          .table-bordered td, .table-bordered th {
              border: 1px solid #dee2e6;
          }
          .table td, .table th {
            padding: 0.75rem;
          }
          .badge {
            display: inline-block;
            padding: 0.35em 0.65em;
            font-size: 0.75em;
            font-weight: 700;
            line-height: 1;
            color: #fff;
            background-color: rgb(159, 159, 159);
            text-align: center;
            white-space: nowrap;
            vertical-align: baseline;
            border-radius: 0.25rem;
          }
          .margin{
            margin:2px!important;
          }
        </style>
        `);
      mywindow.document.write('</head><body>');
      mywindow.document.write(`<h2 class="margin" style="text-transform: uppercase;">${ this.conteudo_mesal.cabecario.escola }</h2>`);
      mywindow.document.write(`<h3 class="margin" style="text-transform: uppercase;">Conteúdo Aplicado</h3>`);
      mywindow.document.write(`<h3 class="margin" ><b style="text-transform: uppercase;">${this.mes_c}</b> </h3>`);
      mywindow.document.write(`<h3 class="margin" style="text-transform: uppercase;">${ this.conteudo_mesal.cabecario.segmento }</h3>`);
      mywindow.document.write(`<h3 class="margin" style="text-transform: uppercase;">${ this.conteudo_mesal.cabecario.serie } - ${ this.conteudo_mesal.cabecario.turma }</h3>`);
      mywindow.document.write(`${document.getElementById('dadosrel').innerHTML}`);
      mywindow.document.write('</body></html>');
      mywindow.print();
      mywindow.close();
      return true;
    },

    toastErr(msg){
      this.$vaToast.init({
        message: msg,
        iconClass: "fa-star-o", position: "top-right",
        duration: 3500, fullWidth: false, color: "danger",
      });
      return false
    },

    toastSuccess(msg){
      this.$vaToast.init({
        message: msg,
        iconClass: "fa-star-o", position: "top-right",
        duration: 3500, fullWidth: false, color: "success",
      });
    }
  },
});
</script>

<style scoped>
#noprint {
  display: block;
}
#printme {
  display: none;
}

@media print {
  #noprint {
    display: none;
  }
  #printme {
    display: block;
  }
}
</style>