<template>
  <div>

    <div class="container_fluid_calendario">
      <div class="row container_calendario">
        <!-- <div>
          <span class="badge" :style="{ backgroundColor: '#2279e5' }"  title="Números de dias Letivos do mês">{{ diasLetivoMensal }}</span>
        </div> -->
        <div class="col-md-12" style=" padding: 15px;">
          <div class="row">
            <div class="col-md-2 col-2">
              <pm-Button  icon="pi pi-chevron-left"  v-if="mes=='fevereiro'"
                class="p-button-raised p-button-rounded  mr-2 btn-semsombra botao_calendario_setas" @click="test('janeiro', 'Janeiro')"
              />
              <pm-Button  icon="pi pi-chevron-left"  v-if="mes=='marco'"
                class="p-button-raised p-button-rounded  mr-2 btn-semsombra botao_calendario_setas" @click="test('fevereiro', 'Fevereiro')"
              />
              <pm-Button  icon="pi pi-chevron-left"  v-if="mes=='abril'"
                class="p-button-raised p-button-rounded  mr-2 btn-semsombra botao_calendario_setas" @click="test('marco', 'Março')"
              />
              <pm-Button  icon="pi pi-chevron-left"  v-if="mes=='maio'"
                class="p-button-raised p-button-rounded  mr-2 btn-semsombra botao_calendario_setas" @click="test('abril', 'Abril')"
              />
              <pm-Button  icon="pi pi-chevron-left"  v-if="mes=='junho'"
                class="p-button-raised p-button-rounded  mr-2 btn-semsombra botao_calendario_setas" @click="test('maio', 'Maio')"
              />

              <pm-Button  icon="pi pi-chevron-left"  v-if="mes=='julho'"
                class="p-button-raised p-button-rounded  mr-2 btn-semsombra botao_calendario_setas" @click="test('junho', 'Junho')"
              />
              <pm-Button  icon="pi pi-chevron-left"  v-if="mes=='agosto'"
                class="p-button-raised p-button-rounded  mr-2 btn-semsombra botao_calendario_setas" @click="test('julho', 'Julho')"
              />
              <pm-Button  icon="pi pi-chevron-left"  v-if="mes=='setembro'"
                class="p-button-raised p-button-rounded  mr-2 btn-semsombra botao_calendario_setas" @click="test('agosto', 'Agosto')"
              />
              <pm-Button  icon="pi pi-chevron-left"  v-if="mes=='outubro'"
                class="p-button-raised p-button-rounded  mr-2 btn-semsombra botao_calendario_setas" @click="test('setembro', 'Setembro')"
              />
              <pm-Button  icon="pi pi-chevron-left"  v-if="mes=='novembro'"
                class="p-button-raised p-button-rounded  mr-2 btn-semsombra botao_calendario_setas" @click="test('outubro', 'Outubro')"
              />
              <pm-Button  icon="pi pi-chevron-left"  v-if="mes=='dezembro'"
                class="p-button-raised p-button-rounded  mr-2 btn-semsombra botao_calendario_setas" @click="test('novembro', 'Novembro')"
              />
            </div>
            <!--<div class="col-md-8 col-8 mes_nome">
              {{ mes_nome }} <div class="numb-dias-letivos" title="Números de dias Letivos Mensal" style="width: 42px!important;">{{ diasLetivoMensal }}</div>
            </div>-->
            <div class="col-md-8 col-8 mes_nome">
              <div class="">
                <select
                  class="p-inputtext p-component"
                  v-model="calendar"
                  @change="test(0, calendar)"
                  style="appearance: revert !important; font-size: 23px !important;font-weight: 700;"
                >
                  <option v-for="n in items" :key="n" :value="n.title">
                    {{ n.title }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-2 col-2" >
              <pm-Button  icon="pi pi-chevron-right"  v-if="mes=='janeiro'"
                class="p-button-raised p-button-rounded  mr-2 btn-semsombra botao_calendario_setas" @click="test('fevereiro', 'Fevereiro')" style="float: left;"
              />
              <pm-Button  icon="pi pi-chevron-right"  v-if="mes=='fevereiro'"
                class="p-button-raised p-button-rounded  mr-2 btn-semsombra botao_calendario_setas" @click="test('marco', 'Março')" style="float: left;"
              />
              <pm-Button  icon="pi pi-chevron-right"  v-if="mes=='marco'"
                class="p-button-raised p-button-rounded  mr-2 btn-semsombra botao_calendario_setas" @click="test('abril', 'Abril')" style="float: left;"
              />
              <pm-Button  icon="pi pi-chevron-right"  v-if="mes=='abril'"
                class="p-button-raised p-button-rounded  mr-2 btn-semsombra botao_calendario_setas" @click="test('maio', 'Maio')" style="float: left;"
              />
              <pm-Button  icon="pi pi-chevron-right"  v-if="mes=='maio'" 
                class="p-button-raised p-button-rounded  mr-2 btn-semsombra botao_calendario_setas" @click="test('junho', 'Junho')" style="float: left;"
              />

              <pm-Button  icon="pi pi-chevron-right"  v-if="mes=='junho'"
                class="p-button-raised p-button-rounded  mr-2 btn-semsombra botao_calendario_setas" @click="test('julho', 'Julho')" style="float: left;"
              />
              <pm-Button  icon="pi pi-chevron-right"  v-if="mes=='julho'"
                class="p-button-raised p-button-rounded  mr-2 btn-semsombra botao_calendario_setas" @click="test('agosto', 'Agosto')" style="float: left;"
              />
              <pm-Button  icon="pi pi-chevron-right"  v-if="mes=='agosto'"
                class="p-button-raised p-button-rounded  mr-2 btn-semsombra botao_calendario_setas" @click="test('setembro', 'Setembro')" style="float: left;"
              />
              <pm-Button  icon="pi pi-chevron-right"  v-if="mes=='setembro'"
                class="p-button-raised p-button-rounded  mr-2 btn-semsombra botao_calendario_setas" @click="test('outubro', 'Outubro')" style="float: left;"
              />
              <pm-Button  icon="pi pi-chevron-right"  v-if="mes=='outubro'"
                class="p-button-raised p-button-rounded  mr-2 btn-semsombra botao_calendario_setas" @click="test('novembro', 'Novembro')" style="float: left;"
              />
              <pm-Button  icon="pi pi-chevron-right"  v-if="mes=='novembro'"
                class="p-button-raised p-button-rounded  mr-2 btn-semsombra botao_calendario_setas" @click="test('dezembro', 'Dezembro')" style="float: left;"
              />
            </div>
          </div>
        </div>
        <div class="col-md-1 container_tabela" v-for="n in tabelaDias" :key="n.dia">
          <div v-if="n.letivo == '0'">
            <button type="button" class="btn calendario_dias">
              <div class="font-semana">
                {{ n.dia_semana }}
              </div>
              <div >
                {{ n.dia }}
              </div>
            </button>
          </div>
          <div v-else>
            <button
              type="button"
              class="btn calendario_dias"
              @click="exibirTabela(n.dia, n.semana, mesNumero, mes);dialogAction()"
            >
              <div v-if="diaSelecionado == n.dia">
                <div
                  class="font-semana color_marcado"
                >
                  {{ n.dia_semana }}
                </div>
                <div class="color_marcado" >
                  {{ n.dia }}
                </div>
              </div>
              <div v-else>
                <div
                  class="font-semana color_letivo"
                >
                  {{ n.dia_semana }}
                </div>
                <div class="color_letivo">
                  {{ n.dia }}
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: "calendario",
  props: {
    tabelaDias: { type: Array, default: () => [] },
    mesNumero: null,
    mes_nome: null,
    mes: null,
    diaSelecionado: null,
  },
  data() {
    return {
      items:[
        {title: 'Janeiro',label:'janeiro',to: '/'},
        {title: 'Fevereiro',label:'fevereiro',to: '/ambiente-professor/presenca/fevereiro'},
        {title: 'Março', label: 'marco', to: '/ambiente-professor/presenca/marco'},
        {title: 'Abril',label: 'abril',to: '/ambiente-professor/presenca/abril'},
        {title: 'Maio',label: 'maio',to: '/ambiente-professor/presenca/maio'},
        {title: 'Junho',label: 'junho',to: '/ambiente-professor/presenca/junho'},
        {title: 'Julho',label: 'julho',to: '/ambiente-professor/presenca/julho'},
        {title: 'Agosto',label:'agosto',to: '/ambiente-professor/presenca/agosto'},
        {title: 'Setembro',label:'setembro',to: '/ambiente-professor/presenca/setembro'},
        {title: 'Outubro',label:'outubro',to: '/ambiente-professor/presenca/outubro'},
        {title: 'Novembro',label: 'novembro',to: '/ambiente-professor/presenca/novembro'},
        {title: 'Dezembro',label: 'dezembro',to: '/ambiente-professor/presenca/dezembro'}
      ],
      calendar:"",
      diasLetivoMensal: 0,
    };
  },
  methods: {
    async test(mes, mesExibir) {
      if(mes == 0){
        const nMes = this.items.find((element) => element.title == mesExibir);
        this.$emit('test', nMes.label, mesExibir);
      }else{
        this.$emit('test', mes, mesExibir);
      }
    },
    exibirTabela(dia, semana, mesNumero, mes){
      this.$emit('exibirTabela', dia, semana, mesNumero, mes);
    },
    dialogAction(){
      this.$emit('dialogAction');
    }
  },
  async beforeMount() {
    this.calendar = this.mes_nome;
    // const res = await axios.get(`/diasLetivosMensal/${this.tabelaDias[0].ano}/${parseInt(this.tabelaDias[0].mes)}/${this.tabelaDias[0].calendario_letivo_id}`)
    // console.log(this.tabelaDias);
    // console.log(res.data)
    // this.diasLetivoMensal = res.data
  }
};
</script>

<style lang="scss" scoped>
  .botao_calendario_setas{
    margin-top: 23px;
  }
  .table-example--pagination {
    padding-top: 20px;
    text-align: center;
    text-align: -webkit-center;
  }
  .cabecario-turma{
    background-color:#2fc982;
    color: #fff;
    margin-right: 10px;
    padding: 8px;
    border-radius: 5px;
    text-transform: uppercase;
  }
  @media(max-width:500px){
    .titulo-links-escolha {
      font-size: 24px;
    }
  }
</style>

<style scoped>
  .badge {
    float: right;
    padding: 6px;
    border-radius: 50%;
    border: 1px solid #ced4da;
    width: 32px;
    font-size: 18px;
    margin-right: 7px;
    color: white;
    margin-top: 10px;
  }
  .th_per1{
    background-color: #f8f9fa !important;
    color: #495057 !important;
    border-right: 1px solid #e9ecef !important;
    border-bottom: 2px solid #e9ecef !important;
    text-align: center;
    padding: 1rem 1rem;
    font-weight: 600;
  }
  .th_pn_aluno{
    width:15%;
  }
  @media(max-width:720px){
    .th_pn_aluno{
      width:90%;
    }
  }

  @media(max-width:500px){
    .cabecario-turma{
      display: inline-grid;
      margin-bottom: 5px;
      font-size: 12px;
    }
  }

  .col-icon-univer{
    padding:0px;padding-top: 0px;padding-left: 10px;width: 4%;
  }
  @media(max-width:500px){
    .col-icon-univer{
      width: 15%;
    }
    .col-cturm{
      width: 80%;
    }
  }

  .escolha-nome-disciplina{
    font-size: 22px;margin-top: 10px;padding-bottom: 15px;
  }

  @media(max-width:500px){
     .escolha-nome-disciplina{
       font-size:16px;
     }
  }


</style>
